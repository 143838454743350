import React from 'react'

class PercentChart extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="w-full pr-9">
        <div className={`h-6 bg-${this.props.color} relative`} style={{width: `${(100 / this.props.max_percent) * this.props.percent}%`}}>
          <div className="absolute -right-11 -top-0 h-full text-dark-blue font-bold">
            {this.props.percent}%
          </div>
        </div>
      </div>
    )
  }
}

export default PercentChart