import React from 'react'
import PrivacyAcknowledgement from './PrivacyAcknowledgement'
import Questionnaire from './Questionnaire'
import Test from './Test'

class TestSet extends React.Component {
  constructor(props) {
    super(props);

    // test has 7 steps
    let totalProgress = 7
    this.props.questionnaires.forEach((questionnaire) => {
      totalProgress += questionnaire.trials.length
    })
    this.state = {
      step: 'acknowledgement',
      questionnaireIndex: 0,
      progress: 0,
      totalProgress: totalProgress
    }
  }

  onQuestionnaireFinished(responses) {
    this.questionnaireResponses = this.questionnaireResponses.concat(responses)
    if (this.props.questionnaires.length - 1 > this.state.questionnaireIndex) {
      this.setState({questionnaireIndex: this.state.questionnaireIndex + 1})
    } else {
      this.setStep('test')
    }
  }

  setStep(newStep) {
    this.setState({step: newStep})
  }

  questionnaireStep() {
    this.setStep('questionnaire')
  }

  componentDidMount() {
    this.questionnaireResponses = []
  }

  updateProgress() {
    this.setState({progress: this.state.progress + 1})
  }

  renderStep() {
    switch(this.state.step) {
      case 'acknowledgement':
        return <PrivacyAcknowledgement nextStep={this.questionnaireStep.bind(this)} 
                                       declinedUrl={this.props.declined_url} 
                                       plusIconUrl={this.props.plus_icon_url}
                                       minusIconUrl={this.props.minus_icon_url}
              ></PrivacyAcknowledgement>
      case 'questionnaire':
        return <Questionnaire
                  currentProgress={this.state.progress}
                  totalProgress={this.state.totalProgress}
                  updateProgress={this.updateProgress.bind(this)}
                  key={this.state.questionnaireIndex}
                  onQuestionnaireFinished={this.onQuestionnaireFinished.bind(this)}
                  data={this.props.questionnaires[this.state.questionnaireIndex]} />
      case 'test':
        return <Test
                  currentProgress={this.state.progress}
                  totalProgress={this.state.totalProgress}
                  updateProgress={this.updateProgress.bind(this)}
                  data={this.props.test_data}
                  onTestFinished={this.onTestFinished.bind(this)}
                  hostUrl={this.props.host_url} />
    }
  }

  onTestFinished(resultD, trialsData) {
    this.postTestResult(resultD, trialsData)
  }

  postTestResult(resultD, trialsData) {
    trialsData = trialsData.filter((trial) => trial['export']);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ result: resultD, responses: this.questionnaireResponses, trials: trialsData })
    };
    fetch(this.props.result_url, requestOptions)
        .then(response => response.json())
        .then(data => window.location.href = data.result_url);
  }

  render() {
    return (
      <div>
        { this.renderStep() }
      </div>
    )
  }
}

export default TestSet