import React, { useState, useEffect } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FFF0C5', '#FF564F', '#D0EA64'];

const Select = (props) => {

  return (
    <label>
      {props.label}
      <br />
      <select onChange={props.onChange}>
        <option></option>
        {
          props.options.map(v => (
            <option key={v.id} value={v.id}>{v.text}</option>
          ))
        }
      </select>
    </label>
  )
}

const QuestionFilter = (props) => {
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();

  return <div>
      <Select label="Questionnaire: " options={props.questionnaires} onChange={(e) => setSelectedQuestionnaire(props.questionnaires.find((q) => q.id == e.target.value)) } />
      <br />
      {
        selectedQuestionnaire ?
          <Select label="Question: " options={selectedQuestionnaire.questions} onChange={(e) => setSelectedQuestion(selectedQuestionnaire.questions.find((q) => q.id == e.target.value)) } />
        : null
      }
      <br />
      {
        selectedQuestionnaire && selectedQuestion ?
          <Select label="Answer: " options={selectedQuestion.answers} onChange={(e) => {            
            let answer = selectedQuestion.answers.find((q) => q.id == e.target.value)          
            setSelectedAnswer(answer)
            props.questionFilters[props.index] = {
              questionnaire: selectedQuestionnaire,
              question: selectedQuestion,
              answer: answer
            }
          }} />
        : null
      }
  </div>
}

const FilteredChart = (props) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [data, setData] = useState();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [questionFilters, setQuestionFilters] = useState([{}])
  const [operator, setOperator] = useState('and')

  const refreshData = (startDate, endDate, questionFilters) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    const filterValues = questionFilters && questionFilters.filter((filter) => filter.question && filter.question.id && filter.answer && filter.answer.id).
        map((filter) => {
            return {question_id: filter.question.id, answer_id: filter.answer.id}
          }
        )
    fetch(`${props.data_url}?`+ new URLSearchParams({
      start_date: (startDate && startDate.toISOString()) || '',
      end_date: (endDate && endDate.toISOString()) || '',
      filters: filterValues ? JSON.stringify(filterValues) : '',
      operator: operator
      }), requestOptions)
      .then(response => response.json())
      .then(d => {
        setData(d)
      });
  }

  useEffect(() => {
    if (!selectedQuestion && !selectedAnswer && !startDate && !endDate) {
      refreshData()
    }
    forceUpdate()
  }, [startDate, endDate, selectedQuestion, selectedAnswer]);

  return (
    <div>
      <h2>Date filter</h2>
      <label>Start date: <DatePicker selected={startDate} onChange={(date) => {
        setStartDate(date)
        refreshData(date, endDate, questionFilters)
      }} /></label><br />
      <label>End date: <DatePicker selected={endDate} onChange={(date) => {
        setEndDate(date)
        refreshData(startDate, date, questionFilters)
      }} /></label><br />
      <h2>Question filter</h2>
      <button onClick={() => {
        questionFilters.push({});        
        setQuestionFilters(questionFilters);
        forceUpdate();
      }}>Add question filter</button>
      <br />
      <Select label="Operator: " options={[{id: 'and', text: 'and'}, {id: 'or', text: 'or'}]} onChange={(e) => setOperator(e.target.value) } />
      <br />
      {
        questionFilters.map((data, index) => <div>
            <QuestionFilter key={index} { ...{...props, index, questionFilters} } />
            <button onClick={() => {
              questionFilters.splice(index, 1);
              setQuestionFilters(questionFilters);
              forceUpdate();
            }}>X</button>
          </div>
          )
      }
      <br />
      <button onClick={() => refreshData(startDate, endDate, questionFilters)}>Refresh</button>
      {
        data ?
          data.length == 0 ?
            <div>No data</div>
          :<PieChart width={500} height={500}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={(entry) => `${entry.name} - ${entry.value}`}
              outerRadius={120}
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={false}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        : null
      }
    </div>
  )
}

export default FilteredChart;