import React, { useState } from "react";
import t from './../lib/translate'

const PrivacyAcknowledgement = (props) => {
  return (
    <>
      <h1 className="text-xl lg:text-4xl mb-16">{t().privacy_acknowledgement.headline}</h1>
      <div className="mb-10" dangerouslySetInnerHTML={{__html: t().privacy_acknowledgement.paragraph1}}></div>

      <div className="flex justify-center">
        <div className="pt-10 pl-9 pr-12 pb-14 bg-grey7 mb-10 max-w-244 w-full rounded-lg text-dark-blue">
            <input type="checkbox" name="panel" id="conditions" className="hidden" />
            <label htmlFor="conditions" className="relative block flex accordion-header items-center">
              <div className="text-3xl text-dark-blue flex-1 font-thin">
                {t().privacy_acknowledgement.data_privacy_headline}
              </div>
              <div className="right-icon">
                <div className="open">
                  <img src={props.plusIconUrl} alt="plus icon for closed state"/>
                </div>
                <div className="close">
                  <img src={props.minusIconUrl} alt="minus icon for open state"/>
                </div>
              </div>
            </label>            
            <div className="accordion__content overflow-hidden bg-grey-lighter text-dark-blue" dangerouslySetInnerHTML={{__html: t().privacy_acknowledgement.data_privacy_content}}>
            </div>  
        </div>
      </div>

      <div className="flex flex-col lg:flex-row mb-10">
          <div className="w-full lg:w-1/3 flex justify-center lg:justify-start">
          </div>
          <div className="flex justify-center w-full lg:w-1/3">
            <button className="btn btn-primary w-full lg:w-auto" onClick={() => props.nextStep() }>{t().privacy_acknowledgement.agree_button}</button>
          </div>
          <div className="flex justify-center lg:justify-end w-full lg:w-1/3 w-full lg:w-auto text-center">
            <a href={props.declinedUrl} className="btn w-full lg:w-60 btn-skip">{t().privacy_acknowledgement.decline_button}</a>
          </div>
      </div>
    </>
  );
};

export default PrivacyAcknowledgement;
