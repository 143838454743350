import React, { useState } from "react";
import { Helmet } from "react-helmet";
import t from './../lib/translate'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const CloseIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#EBEDF1"
      />
      <path
        d="M20.1578 12.6905C20.3921 12.4562 20.3921 12.0763 20.1578 11.842C19.9235 11.6077 19.5436 11.6077 19.3093 11.842L20.1578 12.6905ZM11.8424 19.3089C11.608 19.5432 11.608 19.9231 11.8424 20.1574C12.0767 20.3917 12.4566 20.3917 12.6909 20.1574L11.8424 19.3089ZM19.3091 20.1574C19.5434 20.3917 19.9233 20.3917 20.1576 20.1574C20.392 19.9231 20.392 19.5432 20.1576 19.3089L19.3091 20.1574ZM12.6907 11.842C12.4564 11.6077 12.0765 11.6077 11.8422 11.842C11.6079 12.0763 11.6079 12.4562 11.8422 12.6905L12.6907 11.842ZM19.3093 11.842L11.8424 19.3089L12.6909 20.1574L20.1578 12.6905L19.3093 11.842ZM20.1576 19.3089L12.6907 11.842L11.8422 12.6905L19.3091 20.1574L20.1576 19.3089Z"
        fill="#000229"
      />
    </svg>
  );
};

const Unchecked = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke="#EAEBEC" />
    </svg>
  );
};

const Checked = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#DC98A4" />
      <circle cx="10" cy="10" r="5" fill="#fff" />
    </svg>
  );
};

const HOVER_STATES = {
  instagram: "INSTAGRAM",
};

const Modal = (props) => {
  const [show, setShow] = useState(false);

  const [currentTab, setCurrentTab] = useState("first");

  const [selectedPreview, setSelectedPreview] = useState("first");
  const [dynamicMetaTags, setDynamicMetaTags] = useState({
    image: "https://ia.media-imdb.com/images/rock.jpg",
  });

  const shareUrl = () => props.images[currentTab][0].share_url

  return (
    <>
      <Helmet>
        <meta property="og:title" content="My Test Results" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={shareUrl()} />
        <meta property="og:image" content={dynamicMetaTags.image} />
      </Helmet>
      <button className="share-button" onClick={() => setShow(true)}>{t().modal.share_test} <img src={props.arrowImageUrl} className="inline-block ml-2 w-12 h-12" alt={t().modal.share_test} /></button>

      {show ? (
        <div className="modal-container" id="modal-opened">
          <div className="modal">
            <div className="modal__details">
              <div className="flex w-full justify-center">
                <h1 className="text-2xl">{t().modal.headline}</h1>
                <div
                  onClick={() => setShow(false)}
                  className="absolute right-5"
                >
                  <CloseIcon />
                </div>
              </div>
              <p className="text-base text-dark-blue opacity-50 pt-8 pb-8">
                {t().modal.description}
              </p>

              <div className="flex w-full justify-center">
                <button
                  onClick={() => setCurrentTab("first")}
                  className={`${
                    currentTab === "first" ? "active-tab" : "text-dark-blue"
                  } text-base`}
                >
                  {t().modal.gifs_description}
                </button>
                <button
                  onClick={() => setCurrentTab("second")}
                  className={`${
                    currentTab === "second" ? "active-tab" : "text-dark-blue"
                  } text-base mr-5 ml-5`}
                >
                  {t().modal.statements_description}
                </button>
                <button
                  className={`${
                    currentTab === "third" ? "active-tab" : "text-dark-blue"
                  } text-base`}
                  onClick={() => setCurrentTab("third")}
                >
                  {t().modal.animations_description}
                </button>
              </div>
            </div>
            <div className="content">
              <h1 className="text-lg pt-8 pb-8">{t().modal.cover_headline}</h1>

              <div className="flex w-full justify-around flex-wrap">
                {["first"].map((i, index) => {
                  return (
                    <div
                      className={`flex justify-center flex-col items-center `}
                      onClick={() => {
                        setSelectedPreview(i);
                      }}
                    >
                      <div
                        className={`preview ${
                          selectedPreview === i ? "selected" : ""
                        } ${i}`}
                      >
                        <img src={props.images[currentTab][index].url} alt={props.images[currentTab][index].alt} className="w-full h-full" />
                      </div>
                    </div>
                  );
                })}
              </div>

              <h1 className="text-lg pt-8 pb-8">{t().modal.share_with_headline}</h1>

              <div className="flex w-full justify-center">
                <div className="flex">
                  <FacebookShareButton url={shareUrl()}>
                    <div className="meta social-media-icon"></div>
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareUrl()}>
                    <div className="linkedn social-media-icon"></div>
                  </LinkedinShareButton>
                  <TwitterShareButton url={shareUrl()}>
                    <div className="twitter social-media-icon"></div>
                  </TwitterShareButton>
                  <WhatsappShareButton url={shareUrl()}>
                    <div className="whatsapp social-media-icon"></div>
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
