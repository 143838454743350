import React from 'react'
import {initJsPsych} from 'jspsych'
import jsPsychHtmlKeyboardResponse from '@jspsych/plugin-html-keyboard-response'
import jsPsychPreload from '@jspsych/plugin-preload'
import jsPsychIatHtml from './../jspsych/iat-html'//'@jspsych/plugin-iat-html'
import ProgressBar, { setTrialProgressBar } from './ProgressBar'
import t from './../lib/translate'

class Test extends React.Component {
  constructor(props) {
    super(props);

    this.step2Reversed = Math.random() > 0.5

    this.state = {
      currentTrial: 1
    }

    this.jsPsych = initJsPsych({
      show_progress_bar: false,
      auto_update_progress_bar: true,
      display_element: 'test_content',
      on_trial_start: (trialData) => {
        this.forceUpdate()
        document.querySelectorAll('.wrong').forEach((element) => element.classList.remove('wrong'))
      },
      on_trial_finish: (trialData) => {
        if (this.jsPsych.getCurrentTrial().stepType == 'introduction') {
          this.props.updateProgress()
        }
      },
      on_finish: () => {
        this.props.onTestFinished(this.calculateResult(), this.jsPsych.data.allData.trials)
      }
    })
    this.timeline = this.generateTrials(this.props.data);
  }

  imageUrl(imagePath) {
    return `${this.props.hostUrl}/${imagePath}`
  }

  renderGroupItem(groupItem) {
    if (groupItem.image_path) {
      return `<div class="w-5/12 m-auto md:w-4/12 lg:w-16"><div style="background-image: url('${this.imageUrl(groupItem.image_path)}')" class="bg-contain bg-center bg-no-repeat w-full border-grey-4 border-2 mb-3.5 pt-3/4 h-0"></div></div>`
    } else {
      return groupItem.name
    }
  }

  renderGroupItems(groupItems) {
    let joinCharacter = (groupItems.length > 0 && groupItems[0].image_path) ? '' : ', '
    return groupItems.map((groupItem) => this.renderGroupItem(groupItem)).join(joinCharacter)
  }

  itemToTimelineVariable(item, side) {
    if (item.image_path) {
      return {type: jsPsychIatHtml, stimulus: `<div style="background-image: url('${this.imageUrl(item.image_path)}')" class="bg-contain bg-no-repeat bg-center h-28 w-[9.3rem] lg:h-60 lg:w-80"></div>`, stim_key_association: side, item: item}
    } else {
      return {type: jsPsychIatHtml, stimulus: item.name, stim_key_association: side, item: item}
    }
  }

  itemsToTimelineVariables(leftItems, rightItems) {
    let items = leftItems.map((item) => this.itemToTimelineVariable(item, 'left')).concat(
      rightItems.map((item) => this.itemToTimelineVariable(item, 'right'))
    )
    return this.randomizeItemsWithoutRepetitions(items);
  }

  introductionBlock(leftGroups, rightGroups, testInformation) {
    let leftText = leftGroups.map((group) => group.name).join(` ${t().test.or} `)
    let rightText = rightGroups.map((group) => group.name).join(` ${t().test.or} `)
    return {
      type: jsPsychHtmlKeyboardResponse,
      stimulus: `
      <div>
        <h2 class="touch:hidden notouch:block uppercase text-2xl font-bold mb-6 lg:mt-18">${t().test.introduction_heading}</h2>
        <div class="touch:hidden notouch:flex">
          <div class="mr-20">
            ${t().test.introduction_left.replace('%{category}', leftText)}
          </div>
          <div>
            ${t().test.introduction_right.replace('%{category}', rightText)}
          </div>
        </div>
        <div class="notouch:hidden touch:block mb-9 text-base mt-8">
          <div class="mb-6">
            ${t().test.introduction_mobile_left.replace('%{category}', leftText)}
          </div>
          <div>
            ${t().test.introduction_mobile_right.replace('%{category}', rightText)}
          </div>
        </div>
        <div class="mb-6">
          <div class="lg:text-xl font-bold text-base text-center mt-6">
            ${t().test.items_appear}
          </div>
          ${
            testInformation ? `
              <div class="lg:text-xl text-sm text-center">
                ${testInformation}
              </div>`
            : ''
          }
        </div>
        <div class="notouch:hidden touch:block md:hidden h-52">
          <div class="h-20 flex justify-between items-center text-xl text-dark-blue text-center">
            <div class="mx-4 w-1/2 flex justify-center items-center">
              ${leftText}
            </div>
            <div class="mx-4 w-1/2 flex justify-center items-center">
              ${rightText}
            </div>
          </div>
          <div class="h-20 flex mb-5 text-dark-blue font-bold">
            <div class="mx-4 w-1/2 h-full border border-dark-blue flex justify-center items-center">
              ${t().test.left}
            </div>
            <div class="mx-4 w-1/2 h-full border border-dark-blue flex justify-center items-center">
              ${t().test.right}
            </div>
          </div>
        </div>
        <div class="h-56 touch:hidden notouch:flex justify-center relative">
          <div class="bg-keyboard-e bg-cover w-full h-full">
          </div>
          <div class="bg-keyboard-i bg-cover w-full h-full bg-center">
          </div>
        </div>
        <div class="text-center flex justify-center">
          <div class="max-w-lg">${t().test.mistake_description} ${t().test.speed_description}</div>
        </div>
        <div class="touch:block notouch:hidden text-center text-xl mt-5">
          ${t().test.start_description}
        </div>
        <div class="touch:hidden notouch:flex justify-center">
          <div class="bg-keyboard-space bg-cover bg-center bg-bottom text-2xl text-center h-56 max-w-xl">
            <div class="mt-11">${t().test.start_mobile_description}</div>
          </div>
        </div>
      </div>
      `,
      stepType: 'introduction',
      choices: ' '
    }
  }

  containsRepetitions(items) {
    return items.some((item, index, items) => (index > 0 && item.item.name == items[index - 1].item.name))
  }

  randomizeItemsWithoutRepetitions(items) {
    let iterations = 100; // in some cases it could be impossible to randomize without repetitions
    let randomizedItems;
    do {
      randomizedItems = this.jsPsych.randomization.shuffle(items);
      iterations -= 1;
    } while(this.containsRepetitions(randomizedItems) && iterations > 0);
    return randomizedItems;
  }

  debug() {
    return window.location.href.includes('localhost')
  }

  trialBlock(leftGroups, rightGroups, leftItems, rightItems, trialCount, iatType) {
    let trialCountHalf = trialCount / 2
    // if (this.debug()) {
    //   trialCountHalf = 1;
    // }
    let randomizedLeftItems = this.jsPsych.randomization.sampleWithReplacement(leftItems, trialCountHalf);
    let randomizedRightItems = this.jsPsych.randomization.sampleWithReplacement(rightItems, trialCountHalf);
    return {
      timeline: [
        {
          type: this.jsPsych.timelineVariable('type'),
          stimulus: this.jsPsych.timelineVariable('stimulus'),
          stim_key_association: this.jsPsych.timelineVariable('stim_key_association'),
          html_when_wrong: '',
          bottom_instructions: `<p>${t().test.wrong_description}</p>`,
          force_correct_key_press: true,
          display_feedback: true,
          trial_duration: 3000, //Only if display_feedback is false
          left_category_key: 'e',
          right_category_key: 'i',
          left_category_label: leftGroups.map((group) => group.name),
          right_category_label: rightGroups.map((group) => group.name),
          response_ends_trial: true,
          data: {
            iat_type: iatType,
            original_stimulus: this.jsPsych.timelineVariable('item').name,
            left_label: leftGroups.map((group) => group.name).join(` ${t().test.or} `),
            right_label: rightGroups.map((group) => group.name).join(` ${t().test.or} `),
            export: true,
            item: this.jsPsych.timelineVariable('item')
          },
          display: (trial) => {
                        let html_str =
                        `
                        <div class="w-full">
                          <div class="text-center flex justify-center lg:mt-10 mt-5">
                          <div>${t().test.mistake_description}</div>
                          </div>
                          <div class="justify-between notouch:hidden touch:flex mt-5 text-dark-blue">
                            <div id='trial_left_align' class="w-full">
                              <div class="h-10 flex justify-center items-center text-xl">${trial.left_category_label.join(` ${t().test.or} `)}</div>
                            </div>
                            <div id='trial_right_align' class="w-full">
                              <div class="h-10 flex justify-center items-center text-xl">${trial.right_category_label.join(` ${t().test.or} `)}</div>
                            </div>
                          </div>
                          <div id='jspsych-iat-stim' class="flex justify-center items-center lg:h-60 h-28 my-10 mb-16 lg:mb-10 uppercase font-bold text-4xl text-dark-blue">
                            ${trial.stimulus}
                          </div>
                          <div class="justify-between notouch:flex touch:hidden">
                            <div id='trial_left_align_notouch'>
                              <div class="relative wrong-left w-80 h-24 border flex justify-center items-center text-center border-dark-blue text-2xl">${trial.left_category_label.join(` ${t().test.or} `)}</div>
                              <div class="text-center text-base my-5 text-grey5">${t().test.option_instructions.press} [<span class="uppercase">${trial.left_category_key}</span>] ${t().test.option_instructions.for}</div>
                            </div>
                            <div id='trial_right_align_notouch'>
                              <div class="relative wrong-right w-80 h-24 border flex justify-center items-center text-center border-dark-blue text-2xl">${trial.right_category_label.join(` ${t().test.or} `)}</div>
                              <div class="text-center text-base my-5 text-grey5">${t().test.option_instructions.press} [<span class="uppercase">${trial.right_category_key}</span>] ${t().test.option_instructions.for}</div>
                            </div>
                          </div>
                          <div class="h-24 touch:block notouch:hidden">
                          </div>
                          <div id='wrongImgID' style='position:relative; top: -50px; margin-left: auto; margin-right: auto; left: 0; right: 0'>
                          <div id='wrongImgContainer' style='visibility: hidden; position: absolute; top: -75px; margin-left: auto; margin-right: auto; left: 0; right: 0'>
                            <p>
                              ${trial.html_when_wrong}
                            </p>
                          </div>
                        </div>
                        `
                        return html_str
          }
        }
      ],
      timeline_variables: this.itemsToTimelineVariables(randomizedLeftItems, randomizedRightItems),
      randomize_order: false,
      repetitions: 1,
      display_controls: true
    }
  }

  testDescription(categories) {
    return t().test.test_description.replace('%{test_categories}', categories)
  }

  welcomeBlock(testData) {
    return {
      type: jsPsychHtmlKeyboardResponse,
      display_continue_button: true,
      stimulus: `<p class="text-center">
        ${this.testDescription(t().test.test_categories['test'+ testData.id])}
      </p>
      <p class="text-center">
        <br>
        ${t().test.test_description2}
      </p>
      `,
      choices: ' '
    };
  }

  categoryBlock(groups, items) {
    return {
      type: jsPsychHtmlKeyboardResponse,
      display_continue_button: true,
      stimulus: `
      <p class="notouch:block touch:hidden">${t().test.category_description}<br><br></p>
      <p class="notouch:hidden touch:block">${t().test.category_mobile_description}<br><br></p>
      <table class="categories">
        <tr class="bg-grey2">
          <th class="category">Category</th>
          <th class="items">Items</th>
        </tr>
        ${
          groups.map((_, index) => `<tr>
              <td class="category">${groups[index].name}</td>
              <td class="items">${this.renderGroupItems(items[index])}</td>
            </tr>`
          ).join('')
        }
      </table>
      <br><br><p>${t().test.parts_description}</p>
      <p><strong>${t().test.attention_description}</strong></p>
      `,
      choices: ' '
    }
  }

  calculateResult() {
    // if (this.debug()) {
    //   return 0.8;
    // }

    const totalPerTrial = 40
    let badOld = this.jsPsych.data.get().filter({iat_type: 'bad-old'}).filterCustom((x) => { return x.rt < 10000 && x.rt > 300 });
    let meanCorrectResponsesBadOld = badOld.filter({correct: true}).select('rt').mean();
    let badYoung = this.jsPsych.data.get().filter({iat_type: 'bad-young'}).filterCustom((x) => { return x.rt < 10000 && x.rt > 300 });
    let meanCorrectResponsesBadYoung = badYoung.filter({correct: true}).select('rt').mean();

    let badOldOutTime = totalPerTrial - badOld.trials.length
    let badYoungOutTime = totalPerTrial - badYoung.trials.length

    if (badOldOutTime + badYoungOutTime >= 8) {
      return false
    }
    // 20 is half of 40, 40 is total trial count for real test
    // 20 is 25 percent of 80
    if ((badOld.filter({correct: false}).trials.length + badYoung.filter({correct: false}).trials.length) >= 20) {
      return false
    }

    // get overall sd
    let sd = badOld.join(badYoung).filter({correct: true}).select('rt').sd();

    // if step 2 is reversed, then calculation should also be reversed
    let randomizationCoeficient = this.step2Reversed ? -1 : 1
    let d = randomizationCoeficient * (meanCorrectResponsesBadYoung - meanCorrectResponsesBadOld) / sd;

    return d
  }

  generateTrials(testData) {
    let step1Reversed = false //Math.random() > 0.5
    let step2Reversed = this.step2Reversed
    let group1 = testData.categories[0]
    let group2 = testData.categories[1]
    let group3 = testData.categories[2]
    let group4 = testData.categories[3]

    let itemsGroup1 = testData.categories[0].items
    let itemsGroup2 = testData.categories[1].items
    let itemsGroup3 = testData.categories[2].items
    let itemsGroup4 = testData.categories[3].items

    // initial order, not reversed
    let data = [
      {group: group3, items: itemsGroup3},
      {group: group4, items: itemsGroup4},
      {group: group1, items: itemsGroup1},
      {group: group2, items: itemsGroup2}
    ]

    // https://git.panter.ch/panter/div-002/-/issues/54
    // group3 group4 reversed
    if (step1Reversed) {
      data[0] = {group: group4, items: itemsGroup4}
      data[1] = {group: group3, items: itemsGroup3}
    }
    // group1 group2 reversed
    if (step2Reversed) {
      data[2] = {group: group2, items: itemsGroup2}
      data[3] = {group: group1, items: itemsGroup1}
    }

    group1 = data[2].group
    group2 = data[3].group
    group3 = data[0].group
    group4 = data[1].group

    itemsGroup1 = data[2].items
    itemsGroup2 = data[3].items
    itemsGroup3 = data[0].items
    itemsGroup4 = data[1].items

    let groupItems = itemsGroup1.concat(itemsGroup2).concat(itemsGroup3).concat(itemsGroup4)

    // manually preload images due to presenting them with timeline variables
    let images = groupItems.map((groupItem) => groupItem.image_path).filter(itemImage => itemImage).map(imageUrl => this.imageUrl(imageUrl));

    let preload = {
      type: jsPsychPreload,
      images: images
    };

    let welcomeBlock = this.welcomeBlock(testData)

    let categoryBlock = this.categoryBlock([group1, group2, group3, group4],
                                           [itemsGroup1, itemsGroup2, itemsGroup3, itemsGroup4])

    let instructionsBlock = this.introductionBlock([group3], [group4])
    let trialBlock = this.trialBlock([group3], [group4], itemsGroup3, itemsGroup4, 20, 'practice')

    let instructionsBlock2 = this.introductionBlock([group1], [group2])
    let trialBlock2 = this.trialBlock([group1], [group2], itemsGroup1, itemsGroup2, 20, 'practice')

    let instructionsBlock3 = this.introductionBlock([group1, group3], [group2, group4])
    let trialBlock3 = this.trialBlock([group1, group3], [group2, group4],
                                      itemsGroup1.concat(itemsGroup3),
                                      itemsGroup2.concat(itemsGroup4), 20, 'practice')

    let instructionsBlock4 = this.introductionBlock([group1, group3], [group2, group4], t().test.introduction1)
    let trialBlock4 = this.trialBlock([group1, group3], [group2, group4],
                                        itemsGroup1.concat(itemsGroup3),
                                        itemsGroup2.concat(itemsGroup4), 40, 'bad-old')

    let instructionsBlock5 = this.introductionBlock([group4], [group3], t().test.introduction2)
    let trialBlock5 = this.trialBlock([group4], [group3], itemsGroup4, itemsGroup3, 20,
                                      'practice')

    let instructionsBlock6 = this.introductionBlock([group1, group4], [group2, group3])
    let trialBlock6 = this.trialBlock([group1, group4], [group2, group3],
                                      itemsGroup1.concat(itemsGroup4),
                                      itemsGroup2.concat(itemsGroup3), 20, 'practice')

    let instructionsBlock7 = this.introductionBlock([group1, group4], [group2, group3], t().test.introduction3)
    let trialBlock7 = this.trialBlock([group1, group4], [group2, group3],
                                        itemsGroup1.concat(itemsGroup4),
                                        itemsGroup2.concat(itemsGroup3), 40, 'bad-young')

    setTrialProgressBar(instructionsBlock)
    setTrialProgressBar(trialBlock)
    setTrialProgressBar(instructionsBlock2)
    setTrialProgressBar(trialBlock2)
    setTrialProgressBar(instructionsBlock3)
    setTrialProgressBar(trialBlock3)
    setTrialProgressBar(instructionsBlock4)
    setTrialProgressBar(trialBlock4)
    setTrialProgressBar(instructionsBlock5)
    setTrialProgressBar(trialBlock5)
    setTrialProgressBar(instructionsBlock6)
    setTrialProgressBar(trialBlock6)
    setTrialProgressBar(instructionsBlock7)
    setTrialProgressBar(trialBlock7)

    let timeline = [];
    timeline.push(preload);
    timeline.push(welcomeBlock);
    timeline.push(categoryBlock);
    timeline.push(instructionsBlock);
    timeline.push(trialBlock);
    timeline.push(instructionsBlock2);
    timeline.push(trialBlock2);
    timeline.push(instructionsBlock3);
    timeline.push(trialBlock3);
    timeline.push(instructionsBlock4);
    timeline.push(trialBlock4);
    timeline.push(instructionsBlock5);
    timeline.push(trialBlock5);
    timeline.push(instructionsBlock6);
    timeline.push(trialBlock6);
    timeline.push(instructionsBlock7);
    timeline.push(trialBlock7);
    return timeline
  }

  componentDidMount() {
    this.jsPsych.run(this.timeline);
  }

  displayContinueButton() {
    return this.jsPsych.getCurrentTrial().display_continue_button
  }

  displayControls() {
    return this.jsPsych.getCurrentTrial().display_controls;
  }

  allowFinishTrialByClick() {
    let mobileVersionElement = document.getElementById('mobile_version')
    if (window.getComputedStyle(mobileVersionElement).display == 'none') {
      return false;
    }
    return this.jsPsych.getCurrentTrial().choices == ' '
  }

  emulateKeyPress(event, key) {
    let element = document.getElementById('test_content')
    element.focus();
    element.dispatchEvent(new KeyboardEvent("keydown", { key }));
    element.dispatchEvent(new KeyboardEvent("keyup", { key }));
  }

  render() {
    return (
      <div className="test-component">
        <h1 className="text-xl lg:text-4xl mb-4 lg:mb-20 font-bold">{t().test.test_title}</h1>
        <ProgressBar jsPsych={this.jsPsych} currentProgress={this.props.currentProgress} totalPages={7} totalProgress={this.props.totalProgress} />
        <div id="test_content" className="w-full border-0 mb-10 flex justify-center" onClick={() => this.allowFinishTrialByClick() && this.jsPsych.finishTrial() }>
        </div>
        {
          this.displayContinueButton() ?
            <div className="flex flex-col lg:flex-row mb-10">
              <div className="flex justify-center w-full lg:w-full">
                <button className="btn btn-primary w-full lg:w-auto" onClick={() => this.jsPsych.finishTrial()}>
                  {t().test.continue}
                </button>
              </div>
            </div>
          : null
        }
        {
          this.displayControls() ?
            <div className="controls touch:flex notouch:hidden justify-between w-full -mt-44 lg:-mt-20">
              <div className="w-full z-40">
                <div className="relative wrong-left h-24 border border-dark-blue ml-4 mr-2.5 cursor-pointer"  onClick={(event) => this.emulateKeyPress(event, 'e')}></div>
              </div>
              <div className="w-full z-40">
                <div className="relative wrong-right h-24 border border-dark-blue mr-4 ml-2.5 cursor-pointer"  onClick={(event) => this.emulateKeyPress(event, 'i')}></div>
              </div>
            </div>
          : null
        }
        <div id="mobile_version" className="touch:flex notouch:hidden"></div>
      </div>
    )
  }
}

export default Test