import React from "react";

export const Instagram = (active) => {
  debugger;
  if (active) {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#a)">
          <path
            d="M.027 16.1c0-5.695 0-8.542 1.126-10.71a10 10 0 0 1 4.265-4.264C7.585 0 10.432 0 16.128 0h15.8c5.694 0 8.542 0 10.71 1.126a10 10 0 0 1 4.264 4.264c1.125 2.168 1.125 5.015 1.125 10.71v15.8c0 5.695 0 8.542-1.126 10.71a10 10 0 0 1-4.264 4.264C40.47 48 37.622 48 31.927 48h-15.8c-5.695 0-8.542 0-10.71-1.126a10 10 0 0 1-4.264-4.264C.027 40.442.027 37.595.027 31.9V16.1Z"
            fill="url(#b)"
          />
          <path
            d="M.027 16.1c0-5.695 0-8.542 1.126-10.71a10 10 0 0 1 4.265-4.264C7.585 0 10.432 0 16.128 0h15.8c5.694 0 8.542 0 10.71 1.126a10 10 0 0 1 4.264 4.264c1.125 2.168 1.125 5.015 1.125 10.71v15.8c0 5.695 0 8.542-1.126 10.71a10 10 0 0 1-4.264 4.264C40.47 48 37.622 48 31.927 48h-15.8c-5.695 0-8.542 0-10.71-1.126a10 10 0 0 1-4.264-4.264C.027 40.442.027 37.595.027 31.9V16.1Z"
            fill="url(#c)"
          />
          <path
            d="M24.002 6.545c-4.74 0-5.335.021-7.197.106-1.858.085-3.126.38-4.236.81a8.547 8.547 0 0 0-3.092 2.014 8.569 8.569 0 0 0-2.014 3.091c-.433 1.11-.727 2.379-.811 4.236-.083 1.862-.105 2.458-.105 7.198s.021 5.333.105 7.195c.086 1.858.38 3.127.811 4.237a8.553 8.553 0 0 0 2.013 3.092 8.546 8.546 0 0 0 3.091 2.014c1.11.432 2.38.726 4.237.811 1.862.085 2.456.106 7.196.106s5.334-.021 7.196-.106c1.858-.085 3.128-.38 4.238-.81a8.54 8.54 0 0 0 3.09-2.015 8.57 8.57 0 0 0 2.014-3.092c.43-1.11.724-2.379.811-4.236.084-1.862.106-2.456.106-7.196s-.022-5.335-.106-7.197c-.087-1.858-.382-3.127-.81-4.237a8.567 8.567 0 0 0-2.015-3.091 8.53 8.53 0 0 0-3.091-2.013c-1.113-.432-2.382-.726-4.24-.811-1.862-.085-2.454-.106-7.196-.106h.005Zm-1.565 3.146h1.565c4.66 0 5.213.017 7.053.1 1.702.078 2.626.362 3.24.601a5.398 5.398 0 0 1 2.007 1.306c.61.61.989 1.192 1.306 2.007.239.615.523 1.538.6 3.24.084 1.84.103 2.393.103 7.051s-.019 5.21-.102 7.05c-.078 1.703-.362 2.626-.601 3.24a5.398 5.398 0 0 1-1.306 2.006c-.611.61-1.191.989-2.006 1.305-.615.24-1.539.524-3.24.602-1.84.084-2.393.102-7.054.102-4.66 0-5.213-.019-7.052-.102-1.702-.079-2.626-.363-3.241-.602a5.405 5.405 0 0 1-2.008-1.305 5.41 5.41 0 0 1-1.306-2.007c-.239-.614-.524-1.538-.6-3.24-.085-1.84-.101-2.392-.101-7.053 0-4.662.016-5.211.1-7.051.078-1.702.362-2.626.601-3.241a5.408 5.408 0 0 1 1.306-2.007c.611-.611 1.193-.99 2.008-1.306.615-.24 1.539-.524 3.24-.602 1.61-.073 2.235-.095 5.488-.098v.004Zm10.882 2.898a2.094 2.094 0 1 0 0 4.19 2.094 2.094 0 0 0 0-4.19Zm-9.317 2.447a8.964 8.964 0 1 0 .002 17.928 8.964 8.964 0 0 0-.002-17.928Zm0 3.146a5.818 5.818 0 1 1 0 11.636 5.818 5.818 0 0 1 0-11.636Z"
            fill="#fff"
          />
        </g>
        <defs>
          <radialGradient
            id="b"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 -47.5715 44.2453 0 12.777 51.697)"
          >
            <stop stop-color="#FD5" />
            <stop offset=".1" stop-color="#FD5" />
            <stop offset=".5" stop-color="#FF543E" />
            <stop offset="1" stop-color="#C837AB" />
          </radialGradient>
          <radialGradient
            id="c"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="rotate(78.681 -6.116 -3.159) scale(21.2647 87.6539)"
          >
            <stop stop-color="#3771C8" />
            <stop offset=".128" stop-color="#3771C8" />
            <stop offset="1" stop-color="#60F" stop-opacity="0" />
          </radialGradient>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h48v48H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#a)">
        <path
          d="M16.127.5h15.8c2.856 0 4.968 0 6.637.139 1.663.138 2.842.41 3.843.93a9.5 9.5 0 0 1 4.05 4.052c.52 1 .793 2.18.931 3.843.139 1.669.14 3.78.14 6.636v15.8c0 2.856-.001 4.967-.14 6.636-.138 1.663-.41 2.843-.93 3.843a9.5 9.5 0 0 1-4.051 4.051c-1 .52-2.18.793-3.843.931-1.67.139-3.78.139-6.637.139h-15.8c-2.855 0-4.967 0-6.636-.139-1.663-.138-2.842-.41-3.843-.93a9.5 9.5 0 0 1-4.051-4.052c-.52-1-.793-2.18-.93-3.843-.14-1.669-.14-3.78-.14-6.636V16.1c0-2.856 0-4.967.14-6.636.137-1.663.41-2.843.93-3.843A9.5 9.5 0 0 1 5.648 1.57c1-.52 2.18-.793 3.843-.931C11.161.5 13.271.5 16.127.5Z"
          fill="#fff"
          stroke="#EAEAEC"
        />
        <path
          d="M23.956 9c-4.062 0-4.571.018-6.166.09-1.592.073-2.68.325-3.63.695a7.324 7.324 0 0 0-2.65 1.725 7.342 7.342 0 0 0-1.725 2.649c-.371.95-.623 2.038-.695 3.63-.071 1.594-.09 2.104-.09 6.166s.018 4.57.09 6.165c.074 1.592.326 2.679.695 3.63a7.327 7.327 0 0 0 1.725 2.649 7.322 7.322 0 0 0 2.648 1.726c.952.37 2.039.622 3.63.695 1.595.072 2.105.09 6.166.09 4.062 0 4.57-.018 6.165-.09 1.592-.073 2.68-.325 3.631-.695a7.316 7.316 0 0 0 2.648-1.726 7.341 7.341 0 0 0 1.726-2.65c.367-.95.62-2.037.694-3.629.072-1.595.09-2.104.09-6.165s-.018-4.571-.09-6.166c-.074-1.593-.327-2.68-.694-3.63a7.34 7.34 0 0 0-1.726-2.65 7.31 7.31 0 0 0-2.649-1.724c-.953-.37-2.04-.622-3.632-.695C28.522 9.018 28.014 9 23.95 9h.005Zm-1.342 2.695h1.342c3.993 0 4.466.014 6.043.086 1.458.067 2.25.31 2.776.515a4.626 4.626 0 0 1 1.719 1.119 4.643 4.643 0 0 1 1.119 1.72c.205.526.449 1.317.515 2.775.072 1.577.087 2.05.087 6.042 0 3.99-.016 4.464-.087 6.04-.067 1.459-.31 2.25-.515 2.777a4.624 4.624 0 0 1-1.12 1.718 4.625 4.625 0 0 1-1.718 1.118c-.526.206-1.318.449-2.776.516-1.577.071-2.05.087-6.043.087-3.993 0-4.467-.016-6.043-.087-1.458-.068-2.25-.311-2.777-.516a4.63 4.63 0 0 1-1.72-1.119 4.634 4.634 0 0 1-1.119-1.718c-.205-.527-.449-1.318-.515-2.777-.072-1.576-.086-2.05-.086-6.043 0-3.994.014-4.465.086-6.041.067-1.459.31-2.25.515-2.777a4.633 4.633 0 0 1 1.12-1.72 4.64 4.64 0 0 1 1.719-1.119c.527-.206 1.319-.449 2.777-.516 1.38-.062 1.914-.08 4.701-.084v.004Zm9.324 2.483a1.794 1.794 0 1 0 0 3.59 1.794 1.794 0 0 0 0-3.59Zm-7.982 2.097a7.68 7.68 0 1 0 .001 15.36 7.68 7.68 0 0 0-.002-15.36Zm0 2.695a4.985 4.985 0 1 1 0 9.97 4.985 4.985 0 0 1 0-9.97Z"
          fill="#111C41"
          fill-opacity=".3"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
